
import { defineComponent, ref, onMounted } from "vue";

import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import QuotesService from "@/core/services/car/QuotesService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { roles } from "@/store/stateless/store";

// interface ICustomer {
//   id: number;
//   name: string;
//   email: string;
//   company: string;
//   payment: {
//     icon: string;
//     ccnumber: string;
//     label: string;
//   };
//   date: string;
// }
// interface CustomerBodyParam {
//     per_page:string;
//     task_due_dates:string;
//     customer_status_id:number;
//     agent:string;
//     lead_status_id:number;
//     keyword:string;
//     start_date:string;
//     end_date:string;

// }
// interface TransferLeadsParam {
//     customer_ids : [],
//     agent_id : string,
//     notes : string
// }
// const config = {
//   headers: { Authorization: `Bearer ${JwtService.getToken()}` }
//   };

export default defineComponent({
  name: "customers-list",
  components: {},
  data() {
    return {};
  },
  setup() {
    // let customers_obj = ref([]);
    const store = useStore();
    const route = useRoute();
    console.log(route.params); //
    // let bodyParameters = {} as CustomerBodyParam;
    // let transferLeadsParam = {} as TransferLeadsParam;
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Customer Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Company",
        key: "company",
        sortable: true,
      },
      {
        name: "Payment Method",
        key: "paymentMethod",
        sortingField: "payment.label",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "date",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);
    let sales_agent_id: any;

    // const customers = ref<Array<ICustomer>>([]);
    // const initCustomers = ref<Array<ICustomer>>([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref({});
    let customer_obj = ref<Array<any>>([
      {
        id: 1,
        name: "borer",
        email: "borer.aditya@example.org",
        phone_number: 1 - 838 - 564 - 7179,
        agent: 2,
        last_visited_at: "1990-11-14 11:58:45",
        current_visited_at: "1990-11-14 11:58:45",
        created_at: "2021-09-29T06:27:54.000000Z",
        updated_at: "2021-09-30T11:58:00.000000Z",
        task_count: 0,
        hot_lead: 0,
        agent_details: {
          id: 2,
          name: "ashwin",
        },
      },
    ]);
    // bodyParameters = {
    //     per_page:"50",
    //     task_due_dates:"all",
    //     customer_status_id:-1,
    //     agent:"mine",
    //     lead_status_id:1,
    //     keyword:"",
    //     start_date:"",
    //     end_date:""
    //   }
    // transferLeadsParam = {
    //     customer_ids : [],
    //     agent_id : "",
    //     notes : ""
    // }
    onMounted(() => {
      if(!roles.customerdetails.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Quotes Compare", ["Leads & Tasks"]);
      // API Action to get customers list
      QuotesService.compareCompQuote({
        car_lead_driver_details_id: route.params.driverDetailsId,
        lead_id: route.params.leadId,
      });
      QuotesService.compareTplQuote({
        car_lead_driver_details_id: route.params.driverDetailsId,
        lead_id: route.params.leadId,
      });
    });

    const search = ref<string>("");

    const notes = ref<string>("");

    return {
      roles,
    };
  },
});
